<template>
  <div class="pcm-wrapper">
    <div class="header">
      <div class="logo"></div>
      <div class="contactBox">
        <!-- <div class="officialWebsite" @click="jumpOfficialWeb"></div> -->
        <div class="telegram" @click="toTg"></div>
        <div class="potato" @click="toPotato"></div>
        <div class="twitter" @click="toTwitter"></div>
      </div>
    </div>
    <swiper ref="swiper" :options="swiperOption" class="swiper">
      <swiper-slide class="swiper-slide bg1" @click.stop>
        <div class="bgBorder"></div>
        <div class="figureBg">
          <div class="downloadBox">
            <div class="qrcodeBox">
              <img class="qrcode" :src="qrImg" alt="" />
            </div>
            <div class="downloadBtnBox">
              <div class="iosBtn"></div>
              <div class="androidBtn"></div>
            </div>
          </div>
        </div>
        <div class="downArrow"></div>
      </swiper-slide>
      <swiper-slide class="swiper-slide bg2" @click.stop>
        <div class="bgTitle2"></div>
        <div class="bgText2"></div>
        <div class="cardBox">
          <div class="cardFirstBox">
            <div class="card cardFirst" id="cardFirst"></div>
            <div class="card cardFirstBack" id="cardFirstBack"></div>
          </div>
          <div class="cardSecondBox">
            <div class="card cardSecond" id="cardSecond"></div>
            <div class="card cardSecondBack" id="cardSecondBack"></div>
          </div>
          <div class="cardThirdBox">
            <div class="card cardThird" id="cardThird"></div>
            <div class="card cardThirdBack" id="cardThirdBack"></div>
          </div>
          <div class="cardFourthBox">
            <div class="card cardFourth" id="cardFourth"></div>
            <div class="card cardFourthBack" id="cardFourthBack"></div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-slide bg3" @click.stop>
        <div class="bgTitle3"></div>
        <div class="bgText3"></div>
        <swiper
          ref="childSwiper"
          :options="childSwiperOption"
          class="childSwiper"
        >
          <swiper-slide class="child-swiper-slide">
            <swiper
              ref="childSwiper2"
              :options="childSwiperOption2"
              class="childSwiper2"
            >
              <swiper-slide class="child-swiper-slide2">
                <div class="childBg childBg1"></div>
              </swiper-slide>
              <swiper-slide class="child-swiper-slide2">
                <div class="childBg childBg2"></div>
              </swiper-slide>
            </swiper>
          </swiper-slide>
          <swiper-slide class="child-swiper-slide">
            <swiper
              ref="childSwiper2"
              :options="childSwiperOption2"
              class="childSwiper2"
            >
              <swiper-slide class="child-swiper-slide2">
                <div class="childBg childBg3"></div>
              </swiper-slide>
              <swiper-slide class="child-swiper-slide2">
                <div class="childBg childBg4"></div>
              </swiper-slide>
            </swiper>
          </swiper-slide>
          <swiper-slide class="child-swiper-slide">
            <swiper
              ref="childSwiper2"
              :options="childSwiperOption2"
              class="childSwiper2"
            >
              <swiper-slide class="child-swiper-slide2">
                <div class="childBg childBg5"></div>
              </swiper-slide>
              <swiper-slide class="child-swiper-slide2">
                <div class="childBg childBg6"></div>
              </swiper-slide>
            </swiper>
          </swiper-slide>
          <swiper-slide class="child-swiper-slide">
            <swiper
              ref="childSwiper2"
              :options="childSwiperOption2"
              class="childSwiper2"
            >
              <swiper-slide class="child-swiper-slide2">
                <div class="childBg childBg7"></div>
              </swiper-slide>
              <swiper-slide class="child-swiper-slide2">
                <div class="childBg childBg8"></div>
              </swiper-slide>
            </swiper>
          </swiper-slide>
          <swiper-slide class="child-swiper-slide">
            <swiper
              ref="childSwiper2"
              :options="childSwiperOption2"
              class="childSwiper2"
            >
              <swiper-slide class="child-swiper-slide2">
                <div class="childBg childBg9"></div>
              </swiper-slide>
              <swiper-slide class="child-swiper-slide2">
                <div class="childBg childBg10"></div>
              </swiper-slide>
            </swiper>
          </swiper-slide>
          <swiper-slide class="child-swiper-slide">
            <swiper
              ref="childSwiper2"
              :options="childSwiperOption2"
              class="childSwiper2"
            >
              <swiper-slide class="child-swiper-slide2">
                <div class="childBg childBg11"></div>
              </swiper-slide>
              <swiper-slide class="child-swiper-slide2">
                <div class="childBg childBg12"></div>
              </swiper-slide>
            </swiper>
          </swiper-slide>
        </swiper>
        <div class="pagination">
          <div class="prev" @click.stop="prev"></div>
          <div
            class="page page1"
            :class="{ selected: currentIndex == 0 }"
            @click.stop="change(0)"
          >
            <div class="avatar avatar1"></div>
            <div class="name">雷电将军</div>
            <div class="border"></div>
          </div>
          <div
            class="page page2"
            :class="{ selected: currentIndex == 1 }"
            @click.stop="change(1)"
          >
            <div class="avatar avatar2"></div>
            <div class="name">八重神子</div>
            <div class="border"></div>
          </div>
          <div
            class="page page3"
            :class="{ selected: currentIndex == 2 }"
            @click.stop="change(2)"
          >
            <div class="avatar avatar3"></div>
            <div class="name">妮露</div>
            <div class="border"></div>
          </div>
          <div
            class="page page4"
            :class="{ selected: currentIndex == 3 }"
            @click.stop="change(3)"
          >
            <div class="avatar avatar4"></div>
            <div class="name">申鹤</div>
            <div class="border"></div>
          </div>
          <div
            class="page page5"
            :class="{ selected: currentIndex == 4 }"
            @click.stop="change(4)"
          >
            <div class="avatar avatar5"></div>
            <div class="name">凝光</div>
            <div class="border"></div>
          </div>
          <div
            class="page page6"
            :class="{ selected: currentIndex == 5 }"
            @click.stop="change(5)"
          >
            <div class="avatar avatar6"></div>
            <div class="name">神里绫华</div>
            <div class="border"></div>
          </div>
          <div class="next" @click.stop="next"></div>
        </div>
      </swiper-slide>
      <swiper-slide class="footer">
        <div class="footerTip1"></div>
        <div class="footerTip2"></div>
        <div class="footerBox">
          <div class="tg" @click="toTg"></div>
          <div class="pt" @click="toPotato"></div>
          <div class="tw" @click="toTwitter"></div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import "swiper/css/swiper.css";
import "animate.css";
export default {
  props: ["qrImg", "os"],
  data() {
    return {
      swiperOption: {
        // 轮播图配置
        direction: "vertical",
        mousewheel: true, // 开启滚轮切换
        speed: 500,
        slidesPerView: "auto",
        allowTouchMove: false,
        clickable: false,
        preventClicks: false,
        paginationClickable: false,
        autoplayDisableOnInteraction: false,
      },
      childSwiperOption: {
        slidesPerView: "auto",
        allowTouchMove: false,
        initialSlide: 0,
      },
      childSwiperOption2: {
        // 轮播图配置
        initialSlide: 0,
        allowTouchMove: false,
        direction: "horizontal",
        autoplay: {
          delay: 2000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
        loop: true, // 循环模式选项
        slidesPerView: 1,
        speed: 1000,
      },
      currentIndex: 0,
      timer: null, // 定时器
      cardFirst: undefined,
      cardFirstBack: undefined,
      cardSecond: undefined,
      cardSecondBack: undefined,
      cardThird: undefined,
      cardThirdBack: undefined,
      cardFourth: undefined,
      cardFourthBack: undefined,
      currentPlayIndex: 0, // 当前卡片播放位置
    };
  },
  mounted() {
    this.cardFirst = document.getElementById("cardFirst");
    this.cardFirstBack = document.getElementById("cardFirstBack");
    this.cardSecond = document.getElementById("cardSecond");
    this.cardSecondBack = document.getElementById("cardSecondBack");
    this.cardThird = document.getElementById("cardThird");
    this.cardThirdBack = document.getElementById("cardThirdBack");
    this.cardFourth = document.getElementById("cardFourth");
    this.cardFourthBack = document.getElementById("cardFourthBack");
    setInterval(() => {
      if (this.currentPlayIndex == 0) {
        this.cardFourth.style.animationPlayState = "paused";
        this.cardFourthBack.style.animationPlayState = "paused";
        this.cardFirst.style.animationPlayState = "running";
        this.cardFirstBack.style.animationPlayState = "running";
        this.currentPlayIndex = 1;
      } else if (this.currentPlayIndex == 1) {
        this.cardFirst.style.animationPlayState = "paused";
        this.cardFirstBack.style.animationPlayState = "paused";
        this.cardSecond.style.animationPlayState = "running";
        this.cardSecondBack.style.animationPlayState = "running";
        this.currentPlayIndex = 2;
      } else if (this.currentPlayIndex == 2) {
        this.cardSecond.style.animationPlayState = "paused";
        this.cardSecondBack.style.animationPlayState = "paused";
        this.cardThird.style.animationPlayState = "running";
        this.cardThirdBack.style.animationPlayState = "running";
        this.currentPlayIndex = 3;
      } else if (this.currentPlayIndex == 3) {
        this.cardThird.style.animationPlayState = "paused";
        this.cardThirdBack.style.animationPlayState = "paused";
        this.cardFourth.style.animationPlayState = "running";
        this.cardFourthBack.style.animationPlayState = "running";
        this.currentPlayIndex = 0;
      }
    }, 3000);
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    prev() {
      this.$refs.childSwiper.$swiper.slidePrev();
      this.currentIndex = this.$refs.childSwiper.$swiper.realIndex;
    },
    change(index) {
      this.currentIndex = index;
      this.$refs.childSwiper.$swiper.slideTo(index, 100, false);
    },
    next() {
      this.$refs.childSwiper.$swiper.slideNext();
      this.currentIndex = this.$refs.childSwiper.$swiper.realIndex;
    },
    getApkInfo(type) {
      this.$emit("getApkInfo", type);
    },
    //tg
    toTg() {
      window.location.href = "https://t.me/yaojingdm1";
    },
    toPotato() {
      window.location.href = "https://ptcc.in/yaojingdm1";
    },
    toTwitter() {
      window.location.href = "https://twitter.com/YaoJingDM9";
    },
    jumpOfficialWeb() {
      window.open("https://eros01.com");
    },
  },
};
</script>

<style lang="scss" scoped>
.pcm-wrapper {
  width: 100vw;
  height: 100vh;
  .header {
    height: 10.98%;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 185px 0 299px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    .logo {
      height: 68.4px;
      width: 233px;
      background: url("./../../../assets/images/pc/logo.png") no-repeat;
      background-size: 100% 100%;
    }
    .contactBox {
      display: flex;
      justify-content: center;
      align-items: center;
      .officialWebsite {
        height: 68px;
        width: 68px;
        background: url("./../../../assets/images/pc/officialWebsite.png")
          no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
      .telegram {
        height: 68px;
        width: 68px;
        background: url("./../../../assets/images/pc/telegram.png") no-repeat;
        background-size: 100% 100%;
        margin: 0 0 0 26px;
        cursor: pointer;
      }
      .potato {
        height: 68px;
        width: 68px;
        background: url("./../../../assets/images/pc/potato.png") no-repeat;
        background-size: 100% 100%;
        margin: 0 26px;
        cursor: pointer;
      }
      .twitter {
        height: 68px;
        width: 68px;
        background: url("./../../../assets/images/pc/twitter.png") no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
    }
  }
  .swiper {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
    .swiper-slide {
      height: 100%;
      width: 100%;
      position: relative;
    }
    .bg1 {
      background: url("./../../../assets/images/pc/bg1.webp") no-repeat;
      background-size: 100% 100%;
      .bgBorder {
        position: absolute;
        top: 12.64%;
        left: 50%;
        transform: translate(-50%, 0);
        height: 93.18%;
        width: 96.98%;
        background: url("./../../../assets/images/pc/bgBorder.png") no-repeat;
        background-size: 100% 100%;
      }
      .figureBg {
        position: absolute;
        top: 10.56%;
        left: 0;
        height: 89.44%;
        width: 89.69%;
        background: url("./../../../assets/images/pc/figureBg.webp") no-repeat;
        background-size: 100% 100%;
        .downloadBox {
          height: 116px;
          width: 302px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: absolute;
          bottom: 25%;
          right: 16%;
          .qrcodeBox {
            .qrcode {
              height: 116px;
              width: 116px;
              background: #fff;
            }
          }
          .downloadBtnBox {
            padding: 4.5px;
            border: 1px solid #d3ccc6;
            .iosBtn {
              height: 50px;
              width: 160px;
              background: url("./../../../assets/images/pc/iosBtn.png")
                no-repeat;
              background-size: 100% 100%;
              margin-bottom: 6px;
              cursor: pointer;
            }
            .androidBtn {
              height: 50px;
              width: 160px;
              background: url("./../../../assets/images/pc/androidBtn.png")
                no-repeat;
              background-size: 100% 100%;
              cursor: pointer;
            }
          }
        }
      }
      .downArrow {
        height: 78px;
        width: 120px;
        background: url("./../../../assets/images/pc/downArrow.png") no-repeat;
        background-size: 100% 100%;
        position: absolute;
        z-index: 10;
        bottom: -39px;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
    .bg2 {
      background: url("./../../../assets/images/pc/bg2.webp") no-repeat;
      background-size: 100% 100%;
      .bgTitle2 {
        height: 68px;
        width: 898.8px;
        background: url("./../../../assets/images/pc/bgTitle2.png") no-repeat;
        background-size: 100% 100%;
        position: absolute;
        left: 50%;
        top: 58px;
        transform: translate(-50%, 0);
      }
      .bgText2 {
        height: 41px;
        width: 551px;
        background: url("./../../../assets/images/pc/bgText2.png") no-repeat;
        background-size: 100% 100%;
        position: absolute;
        left: 30.5%;
        bottom: 15.2%;
      }
      .cardBox {
        position: relative;
        left: 20%;
        top: 18%;
        .cardFirstBox {
          height: 476px;
          width: 261px;
          position: absolute;
          left: 0;
          top: 0;
          .cardFirst {
            background: url("./../../../assets/images/pc/card1.webp") no-repeat;
            background-size: 100% 100%;
            animation: transCard 3s linear infinite alternate;
            animation-play-state: paused;
          }
          .cardFirstBack {
            background: url("./../../../assets/images/pc/card2.webp") no-repeat;
            background-size: 100% 100%;
            transform: rotateY(180deg);
            animation: transCardReversal 3s linear infinite alternate;
            animation-play-state: paused;
          }
        }
        .cardSecondBox {
          height: 476px;
          width: 261px;
          position: absolute;
          left: 300px;
          top: 30px;
          .cardSecond {
            background: url("./../../../assets/images/pc/card3.webp") no-repeat;
            background-size: 100% 100%;
            animation: transCard 3s linear infinite alternate;
            animation-play-state: paused;
          }
          .cardSecondBack {
            background: url("./../../../assets/images/pc/card4.webp") no-repeat;
            background-size: 100% 100%;
            transform: rotateY(180deg);
            animation: transCardReversal 3s linear infinite alternate;
            animation-play-state: paused;
          }
        }
        .cardThirdBox {
          height: 476px;
          width: 261px;
          position: absolute;
          left: 600px;
          top: 0;
          .cardThird {
            background: url("./../../../assets/images/pc/card5.webp") no-repeat;
            background-size: 100% 100%;
            animation: transCard 3s linear infinite alternate;
            animation-play-state: paused;
          }
          .cardThirdBack {
            background: url("./../../../assets/images/pc/card6.webp") no-repeat;
            background-size: 100% 100%;
            transform: rotateY(180deg);
            animation: transCardReversal 3s linear infinite alternate;
            animation-play-state: paused;
          }
        }
        .cardFourthBox {
          height: 476px;
          width: 261px;
          position: absolute;
          left: 900px;
          top: 30px;
          .cardFourth {
            background: url("./../../../assets/images/pc/card7.webp") no-repeat;
            background-size: 100% 100%;
            animation: transCard 3s linear infinite alternate;
            animation-play-state: paused;
          }
          .cardFourthBack {
            background: url("./../../../assets/images/pc/card8.webp") no-repeat;
            background-size: 100% 100%;
            transform: rotateY(180deg);
            animation: transCardReversal 3s linear infinite alternate;
            animation-play-state: paused;
          }
        }
        .card {
          height: 100%;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 2;
          transform-style: preserve-3d;
          backface-visibility: hidden;
        }
      }
    }
    .bg3 {
      background: url("./../../../assets/images/pc/bg3.webp") no-repeat;
      background-size: 100% 100%;
      .bgTitle3 {
        height: 68px;
        width: 898.8px;
        background: url("./../../../assets/images/pc/bgTitle3.png") no-repeat;
        background-size: 100% 100%;
        position: absolute;
        left: 50%;
        top: 58px;
        transform: translate(-50%, 0);
      }
      .bgText3 {
        height: 254px;
        width: 560.8px;
        position: absolute;
        top: 33%;
        left: 16.97%;
        z-index: 2;
        background: url("./../../../assets/images/pc/bgText3.png") no-repeat;
        background-size: 100% 100%;
      }
      .childSwiper {
        height: 849px;
        width: 1350px;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;
        overflow: hidden;
        .child-swiper-slide {
          height: 100%;
          width: 100%;
          .childSwiper2 {
            height: 100%;
            width: 100%;
            .child-swiper-slide2 {
              height: 100%;
              width: 100%;
            }
          }
        }
        .childBg {
          height: 100%;
          width: 100%;
        }
        .childBg1 {
          background: url("./../../../assets/images/pc/swiperBg1.webp")
            no-repeat;
          background-size: 1643px 849px;
        }
        .childBg2 {
          background: url("./../../../assets/images/pc/swiperBg2.webp")
            no-repeat;
          background-size: 1643px 849px;
        }
        .childBg3 {
          background: url("./../../../assets/images/pc/swiperBg3.webp")
            no-repeat;
          background-size: 1936px 964px;
        }
        .childBg4 {
          background: url("./../../../assets/images/pc/swiperBg4.webp")
            no-repeat;
          background-size: 1936px 964px;
        }
        .childBg5 {
          background: url("./../../../assets/images/pc/swiperBg5.webp")
            no-repeat;
          background-size: 1514px 927px;
        }
        .childBg6 {
          background: url("./../../../assets/images/pc/swiperBg6.webp")
            no-repeat;
          background-size: 1514px 927px;
        }
        .childBg7 {
          background: url("./../../../assets/images/pc/swiperBg7.webp")
            no-repeat;
          background-size: 1699px 953px;
        }
        .childBg8 {
          background: url("./../../../assets/images/pc/swiperBg8.webp")
            no-repeat;
          background-size: 1699px 953px;
        }
        .childBg9 {
          background: url("./../../../assets/images/pc/swiperBg9.webp")
            no-repeat;
          background-size: 1492px 915px;
        }
        .childBg10 {
          background: url("./../../../assets/images/pc/swiperBg10.webp")
            no-repeat;
          background-size: 1492px 915px;
        }
        .childBg11 {
          background: url("./../../../assets/images/pc/swiperBg11.webp")
            no-repeat;
          background-size: 1460px 895px;
        }
        .childBg12 {
          background: url("./../../../assets/images/pc/swiperBg12.webp")
            no-repeat;
          background-size: 1460px 895px;
        }
      }
      .pagination {
        height: 211px;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.15);
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        .prev {
          height: 90.7px;
          width: 130.2px;
          background: url("./../../../assets/images/pc/prev.webp") no-repeat;
          background-size: 100% 100%;
          margin-right: 19px;
          cursor: pointer;
        }
        .page {
          width: 106px;
          margin: 0 19px;
          cursor: pointer;
          border-radius: 6px;
          overflow: hidden;
          background: linear-gradient(to bottom, #3f3f3f, #131313);
          position: relative;
        }
        .page1:hover,
        .page2:hover,
        .page3:hover,
        .page4:hover,
        .page5:hover,
        .page6:hover {
          .border {
            position: absolute;
            left: 0;
            top: 0;
            width: 106px;
            height: 129px;
            border: 3px solid white;
          }
          .name {
            color: #000;
            background-color: white;
          }
        }
        .selected {
          .border {
            position: absolute;
            left: 0;
            top: 0;
            width: 106px;
            height: 129px;
            border: 3px solid white;
          }
          .name {
            color: #000;
            background-color: white;
          }
        }
        .avatar {
          width: 106px;
          height: 106px;
        }
        .name {
          height: 23px;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          font-size: 15px;
          line-height: 23px;
          text-align: center;
          color: white;
        }
        .avatar1 {
          background: url("./../../../assets/images/pc/avatar1.webp") no-repeat;
          background-size: 100% 100%;
        }
        .avatar2 {
          background: url("./../../../assets/images/pc/avatar2.webp") no-repeat;
          background-size: 100% 100%;
        }
        .avatar3 {
          background: url("./../../../assets/images/pc/avatar3.webp") no-repeat;
          background-size: 100% 100%;
        }
        .avatar4 {
          background: url("./../../../assets/images/pc/avatar4.webp") no-repeat;
          background-size: 100% 100%;
        }
        .avatar5 {
          background: url("./../../../assets/images/pc/avatar5.webp") no-repeat;
          background-size: 100% 100%;
        }
        .avatar6 {
          background: url("./../../../assets/images/pc/avatar6.webp") no-repeat;
          background-size: 100% 100%;
        }
        .next {
          height: 90.7px;
          width: 130.2px;
          background: url("./../../../assets/images/pc/next.webp") no-repeat;
          background-size: 100% 100%;
          margin-left: 19px;
          cursor: pointer;
        }
      }
    }
    .footer {
      height: 260px;
      width: 100%;
      background-color: rgb(0, 0, 0);
      .footerTip1 {
        height: 36px;
        width: 384px;
        background: url("./../../../assets/images/pc/footerTip1.png") no-repeat;
        background-size: 100% 100%;
        margin: 34px auto 0;
      }
      .footerTip2 {
        height: 16px;
        width: 1060px;
        background: url("./../../../assets/images/pc/footerTip2.png") no-repeat;
        background-size: 100% 100%;
        margin: 18px auto 0;
      }
      .footerBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 220px;
        margin: 36px auto 0;
        .tg,
        .pt,
        .tw {
          height: 72px;
          width: 49px;
          cursor: pointer;
        }
        .tg {
          background: url("./../../../assets/images/pc/telegramFooter.png")
            no-repeat;
          background-size: 100% 100%;
        }
        .pt {
          background: url("./../../../assets/images/pc/potatoFooter.png")
            no-repeat;
          background-size: 100% 100%;
        }
        .tw {
          background: url("./../../../assets/images/pc/twitterFooter.png")
            no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
}
// 卡片翻转动画
@keyframes transCard {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(-180deg);
  }
}
@keyframes transCardReversal {
  0% {
    transform: rotateY(-180deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}
</style>
